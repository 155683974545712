import { jsx as _jsx } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { createContext, useContext, useEffect, useMemo, useState, } from 'react';
import getBookingPrices from '../api-requests/prices';
import Loading from '../components/Loading';
import { getTotalBasedOnModalityAndMethod } from '../lib/bookingUtils';
import { PAYMENT_METHODS, PAYMENT_MODALITIES } from '../lib/payments/constant';
import { beginCheckoutDataLayer } from '../lib/payments/post-payment/analytics';
import { useBooking } from './BookingContext';
const contextDefaultValues = {
    paymentMethod: null,
    setPaymentMethod: () => null,
    paymentModality: null,
    setPaymentModality: () => null,
    isCreditCard: false,
    isBankTransfer: false,
    isUpfrontPayment: false,
    isFullPayment: false,
    firstDepartureInLessThan45Days: false,
    firstDepartureInLessThan7Days: false,
    finalPriceForResume: null,
    fullCreditCardFeeAmount: null,
    currentCcFeeInResume: null,
    customerFee: null,
    basePriceWithCustomerFee: null,
    firstPaymentAmount: null,
    fullCardPrice: null,
    creditCardFeeForRestAmount: null,
    depositRestSubtotal: null,
    isStripe: false,
    isInstallments: false,
    isWallet: false,
    depositRestWithCard: null,
    basePrice: null,
    pricePerPerson: null,
    pricePerPersonTotal: null,
    loadingPrices: false,
    depositWithWire: {
        deposit: {
            amount: 0,
            ccFee: 0,
        },
        remaining: {
            amount: 0,
            ccFee: 0,
            subtotal: 0,
        },
    },
    depositWithCard: {
        deposit: {
            amount: 0,
            ccFee: 0,
        },
        remaining: {
            amount: 0,
            ccFee: 0,
            subtotal: 0,
        },
    },
    fullCreditCard: {
        amount: 0,
        ccFee: 0,
    },
    installmentsPayments: [],
};
const PaymentContext = createContext(contextDefaultValues);
export function usePayment() {
    return useContext(PaymentContext);
}
function usePrices({ paymentModality, paymentMethod, }) {
    const { booking } = useBooking();
    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState(null);
    useEffect(() => {
        let timeout = null;
        setLoading(true);
        timeout = setTimeout(() => {
            getBookingPrices({
                bookingId: booking._id,
                paymentModality,
                paymentMethod,
            })
                .then(p => {
                beginCheckoutDataLayer({ booking, prices: p });
                setPrices(p);
            })
                .finally(() => setLoading(false));
        }, 500);
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [booking.leadCurrency]); // leave just booking here
    return { prices, loading };
}
export default function PaymentContextProvider({ children, }) {
    const { booking } = useBooking();
    const firstDepartureInLessThan45Days = useMemo(() => {
        const firstAvailabilityDate = booking.availability.reduce((min, availability) => Math.min(min, +dayjs(availability.startDate)), Infinity);
        return dayjs(firstAvailabilityDate).diff(dayjs(), 'days') < 45;
    }, [booking]);
    const firstDepartureInLessThan7Days = useMemo(() => {
        const firstAvailabilityDate = booking.availability.reduce((min, availability) => Math.min(min, +dayjs(availability.startDate)), Infinity);
        return dayjs(firstAvailabilityDate).diff(dayjs(), 'days') < 7;
    }, [booking]);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [paymentModality, setPaymentModality] = useState(PAYMENT_MODALITIES.FULL);
    useEffect(() => {
        firstDepartureInLessThan45Days
            ? setPaymentModality(PAYMENT_MODALITIES.FULL)
            : null;
        firstDepartureInLessThan7Days
            ? setPaymentMethod(PAYMENT_METHODS.CREDIT_CARD)
            : null;
    }, [firstDepartureInLessThan45Days]);
    const { prices, loading } = usePrices({ paymentModality, paymentMethod });
    if (!prices) {
        return _jsx(Loading, {});
    }
    const { generics, '75-later': upfront, 'total-now': totalNow, installments, } = prices;
    const { totalCustomerFee, basePriceWithCustomerFee, pricePerPerson, pricePerPersonTotal, subtotal, } = generics;
    const defaultMethod = PAYMENT_METHODS.CREDIT_CARD;
    const { computedCalculations, final } = upfront[defaultMethod][defaultMethod];
    const { total: fullPriceNow, totalCcFee } = totalNow[defaultMethod].computedCalculations;
    const deposit = final.find(payment => payment.first)?.data;
    const depositAmount = deposit?.total || 0;
    const depositCcFee = deposit?.summary.find(summary => summary.name === 'credit_card_fee')
        ?.value || 0;
    const isWallet = paymentMethod === PAYMENT_METHODS.WALLETS;
    const isStripe = paymentMethod === PAYMENT_METHODS.STRIPE;
    const isCreditCard = paymentMethod === PAYMENT_METHODS.CREDIT_CARD;
    const isBankTransfer = paymentMethod === PAYMENT_METHODS.WIRE;
    const isUpfrontPayment = paymentModality === PAYMENT_MODALITIES.UP_FRONT;
    const isFullPayment = paymentModality === PAYMENT_MODALITIES.FULL;
    const isInstallments = paymentModality === PAYMENT_MODALITIES.INSTALLMENTS;
    const finalPriceForResume = Number(getTotalBasedOnModalityAndMethod({
        isCreditCard,
        basePriceWithCustomerFee,
        isFullPayment,
        isWallet,
        isUpfrontPayment,
        isInstallments,
        fullPriceNow,
    }));
    const depositWithWire = (() => {
        const { computedCalculations, final } = upfront.wire.wire;
        const deposit = final.find(payment => payment.first)?.data;
        const depositAmount = deposit?.total || 0;
        return {
            deposit: {
                amount: depositAmount,
                ccFee: 0,
            },
            remaining: computedCalculations.upFront.remaining,
        };
    })();
    const currentCcFeeInResume = isCreditCard || isStripe || isWallet ? totalCcFee : 0;
    return (_jsx(PaymentContext.Provider, { value: {
            paymentMethod,
            setPaymentMethod,
            paymentModality,
            setPaymentModality,
            isCreditCard,
            isBankTransfer,
            isUpfrontPayment,
            isFullPayment,
            firstDepartureInLessThan45Days,
            firstDepartureInLessThan7Days,
            finalPriceForResume,
            currentCcFeeInResume,
            customerFee: totalCustomerFee,
            basePriceWithCustomerFee,
            depositWithWire,
            depositWithCard: {
                deposit: {
                    amount: depositAmount,
                    ccFee: depositCcFee,
                },
                remaining: computedCalculations.upFront.remaining,
            },
            fullCreditCard: {
                amount: fullPriceNow,
                ccFee: totalCcFee,
            },
            isStripe,
            isInstallments,
            isWallet,
            basePrice: subtotal,
            pricePerPerson: pricePerPerson,
            pricePerPersonTotal,
            loadingPrices: loading,
            installmentsPayments: installments[defaultMethod].final,
        }, children: children }));
}
