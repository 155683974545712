import * as Sentry from '@sentry/react';
import { confirmPayment } from '../../api-requests/payments';
import { PRE_AUTHORIZATION_PAYMENT_STATUS } from './constant';
export async function handleCardAction(bookingId, requiresActionData, stripe) {
    if (!requiresActionData ||
        requiresActionData.status !==
            PRE_AUTHORIZATION_PAYMENT_STATUS.REQUIRES_ACTION ||
        !requiresActionData.client_secret ||
        !stripe)
        return null;
    const { client_secret: clientSecret } = requiresActionData;
    try {
        await stripe.handleCardAction(clientSecret);
        await confirmPayment(bookingId);
    }
    catch (err) {
        Sentry.captureException(`error in handleCardAction ${bookingId} ${err}`);
        return Promise.reject(err);
    }
}
