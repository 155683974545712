export const ASSETS_URL = process.env.ASSETS_URL;
export var REPOSITORIES;
(function (REPOSITORIES) {
    REPOSITORIES["GUIDE"] = "GUIDE";
    REPOSITORIES["TRIP_COVER"] = "TRIP_COVER";
    REPOSITORIES["TRIP"] = "TRIP";
    REPOSITORIES["LANDING_PAGE"] = "LANDING_PAGE";
    REPOSITORIES["LANDING_PAGE_RELEVANT"] = "LANDING_PAGE_RELEVANT";
    REPOSITORIES["STATIC_PAGE_HEADER"] = "STATIC_PAGE_HEADER";
    REPOSITORIES["STATIC_PAGE"] = "STATIC_PAGE";
    // statics assets
    REPOSITORIES["STATICS_LOGO"] = "STATICS_LOGO";
    REPOSITORIES["STATICS_OTHER_LOGO"] = "STATICS_OTHER_LOGO";
    REPOSITORIES["STATICS_ICON"] = "STATICS_ICON";
    REPOSITORIES["STATICS_FACE"] = "STATICS_FACE";
    REPOSITORIES["STATICS_PAGE_ABOUT"] = "STATICS_PAGE_ABOUT";
    REPOSITORIES["STATICS_PAGE_PRESS"] = "STATICS_PAGE_PRESS";
    REPOSITORIES["STATICS_PAGE_HOME"] = "STATICS_PAGE_HOME";
    REPOSITORIES["STATICS"] = "STATICS";
    REPOSITORIES["AUTHOR"] = "AUTHOR";
})(REPOSITORIES || (REPOSITORIES = {}));
export const DEFAULT_IMAGE = `${ASSETS_URL}/statics/default-trip-cover`;
const pathBuilder = (repository, params = { _id: null }) => {
    const paths = {
        [REPOSITORIES.GUIDE]: `guides/${params._id}/avatar`,
        [REPOSITORIES.TRIP]: `trips/${params._id}/photos`,
        [REPOSITORIES.TRIP_COVER]: `trips/${params._id}/cover`,
        [REPOSITORIES.LANDING_PAGE]: `landing_page/${params._id}/header`,
        [REPOSITORIES.LANDING_PAGE_RELEVANT]: `landing_page/${params._id}/relevant`,
        [REPOSITORIES.STATIC_PAGE_HEADER]: `static_page/${params._id}/header`,
        [REPOSITORIES.STATIC_PAGE]: `static_page/${params._id}/photo`,
        [REPOSITORIES.AUTHOR]: `authors/${params._id}/avatar`,
        // statics assets
        [REPOSITORIES.STATICS_OTHER_LOGO]: `statics/other_logos`,
        [REPOSITORIES.STATICS_LOGO]: `statics/logos`,
        [REPOSITORIES.STATICS_ICON]: `statics/icons`,
        [REPOSITORIES.STATICS_FACE]: `statics/faces`,
        [REPOSITORIES.STATICS_PAGE_ABOUT]: `statics/page/about`,
        [REPOSITORIES.STATICS_PAGE_PRESS]: `statics/page/press`,
        [REPOSITORIES.STATICS_PAGE_HOME]: `statics/page/home`,
        [REPOSITORIES.STATICS]: `statics`,
    };
    return paths[repository];
};
/**
 * restructure datocms image url to assets url
 * @param {string} originalUrl  // datocms url
 * @param {REPOSITORIES} repository
 * @param {PathParams} params
 * @returns assets url
 */
export const repathImg = (originalUrl, repository, params) => {
    // default image
    if (!originalUrl)
        return DEFAULT_IMAGE;
    // if url is new assets cdn
    if (originalUrl.includes(ASSETS_URL || ""))
        return originalUrl;
    const fileName = originalUrl.split('/').pop(); // Ensures fileName is not null
    const newpath = pathBuilder(repository, params);
    return `${ASSETS_URL}/${newpath}/${fileName}`;
};
/**
 * contruct a assets url
 * @param {string} file // this file must exists in assets
 * @param {REPOSITORIES} repository
 * @param {PathParams} params
 * @returns assets url
 */
export const pathImg = (file, repository, params) => {
    // default image
    if (!file)
        return DEFAULT_IMAGE;
    const newpath = pathBuilder(repository, params);
    return `${ASSETS_URL}/${newpath}/${file}`;
};
